import Vue from 'vue';
import App from '@shared/app/components/App.vue';
import router from '@shared/router';
import store from '@shared/store';
import vueConfiguration from './configuration/vue.configuration';
import momentConfiguration from './configuration/moment.configuration';
import validationConfiguration from './configuration/validation.configuration';
import hooksConfiguration from './configuration/hooks.configuration';
import vuetifyConfiguration from './configuration/vuetify.configuration';
import Theme from './model/Theme';
import defaultTheme from './theme/default.theme';
import mutations from '../store/mutations';
import ExtensionPoints from './model/ExtensionPoints';
import extensionPointsConfiguration from './configuration/extensionPoints.configuration';

export default (theme?: Theme, extensionsPoints: ExtensionPoints = {}) => {
  hooksConfiguration();
  vueConfiguration();
  momentConfiguration();
  validationConfiguration();
  extensionPointsConfiguration(extensionsPoints);

  const vuetify = vuetifyConfiguration(theme || defaultTheme);
  store.commit(mutations.THEME_SET, theme || defaultTheme);

  // Ensure httpS in production
  // if (process.env.NODE_ENV === 'production' && window.location.protocol !== 'https:') {
  //   window.location.replace(
  //     `https:${window.location.href.substring(window.location.protocol.length)}`,
  //   );
  // }

  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
};

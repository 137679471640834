import Theme from '@/shared/app/model/Theme';
import defaultTheme from '@/shared/app/theme/default.theme';

const mrsTheme: Theme = {
  ...defaultTheme,
  customerName: 'MRS',
  primaryColor: '#ADC74D',
  secondaryColor: '#37474f',
  accentColor: '#ffc107',
  errorColor: '#f44336',
  warningColor: '#ff9800',
  infoColor: '#00bcd4',
  successColor: '#479e4b',
};

export default mrsTheme;

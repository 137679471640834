import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Support from '../views/Support.vue';
import Communications from '../views/Communications.vue';
import CommunicationsForm from '../views/CommunicationsForm.vue';
import SiteHome from '../views/SiteHome.vue';
import SiteForm from '../views/SiteForm.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: { title: 'MyPlay' },
  },
  {
    name: 'support',
    path: '/help',
    component: Support,
    meta: { title: 'Aide - MyPlay' },
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: { title: 'Connexion - MyPlay' },
  },
  {
    name: 'site_home',
    path: '/site/:id',
    component: SiteHome,
  },
  {
    name: 'communications',
    path: '/communications',
    component: Communications,
    meta: { title: 'Communications - MyPlay' },
  },
  {
    name: 'communications_add',
    path: '/communications/add',
    component: CommunicationsForm,
    meta: { title: 'Nouvelle communication - MyPlay' },
  },
  {
    name: 'communications_edit',
    path: '/communications/edit/:id',
    component: CommunicationsForm,
  },
  {
    name: 'communications_clone',
    path: '/communications/clone/:id',
    component: CommunicationsForm,
  },
  {
    path: '/form/:siteId/:slug',
    name: 'form',
    redirect: (to) => ({
      name: 'form_tab',
      params: {
        siteId: to.params.siteId,
        slug: to.params.slug,
        tabNo: '1',
      },
    }),
    // component: SiteForm,
  },
  {
    path: '/form/:siteId/:slug/:tabNo',
    name: 'form_tab',
    component: SiteForm,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) document.title = to.meta.title;
  return next();
});

export default router;


import Vue from 'vue';
import _ from 'lodash-es';
import Component, { mixins } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Field from '@shared/components/forms/Field.vue';
import Preview from '@shared/components/forms/Preview.vue';
import FormUsage from '@shared/store/models/FormUsage';
import Form from '@shared/store/models/Form';
import HttpMixin from '@shared/http/http.mixin';
import FormEffect from '@shared/store/models/FormEffect';
import Dataset from '@shared/store/models/Dataset';
import FormFieldsGroup from '@shared/store/models/FormFieldsGroup';
import FormUsageTab from '@shared/store/models/FormUsageTab';
import FormDatasetsConfiguration from '@shared/store/models/FormDatasetsConfiguration';
import Datasets from '@shared/store/models/Datasets';
import Site from '@shared/store/models/Site';

@Component({
  components: { Field, Preview },
})
export default class SiteForm extends mixins(Vue, HttpMixin) {
  tab = 0;

  loading = true;

  previewZoom = false;

  dayOfWeekTabSet = false;

  snackbarDayOfWeekTab = false;

  form: Form | null = null;

  site: Site | null = null;

  formUsage: FormUsage | null = null;

  formUsageTab: FormUsageTab | null = null;

  mounted() {
    this.select(this.$route.params.siteId, this.$route.params.slug, this.$route.params.tabNo);
  }

  @Watch('$route.params')
  routeParamsChange(params: any) {
    this.select(params.siteId, params.slug, params.tabNo);
  }

  // Sélection d'un formulaire (site > formUsage > tab#)
  async select(siteId: string, formUsageSlug: string, tabNo: string) {
    this.loading = true;
    const tabNumber = Number(tabNo) || 1;
    if (siteId && formUsageSlug) {
      this.http
        .loadSiteById(siteId)
        .then((site) => {
          this.site = site;
          // On détermine le formUsage
          this.formUsage =
            this.site.formUsages.find((formUsage) => formUsageSlug === formUsage.slug) || null;
          // On récupère le formulaire correspondant
          this.form = _.cloneDeep(this.$store.getters.getFormById(this.formUsage?.form));
          if (this.formUsage) {
            if (Object.keys(this.$route.query).includes('auto')) {
              const day = this.$moment().format('dddd');
              const goodDayTabIndex = _.findIndex(
                this.formUsage.tabs,
                (tab) => tab.name.toLowerCase() === day,
              );
              if (goodDayTabIndex !== -1 && goodDayTabIndex + 1 !== tabNumber) {
                this.$router.replace({
                  name: 'form_tab',
                  params: {
                    siteId,
                    slug: formUsageSlug,
                    tabNo: (goodDayTabIndex + 1).toString(),
                  },
                });
              }
            }
            this.formUsageTab = this.formUsage.tabs[tabNumber - 1];
          }
        })
        .then(() => {
          console.log('here', this.form, this.formUsage, this.formUsageTab);
          if (this.form && this.formUsage && this.formUsageTab) {
            // Merge des datasets Formulaire et Tab
            const datasetsConfiguration: FormDatasetsConfiguration = _.merge(
              {},
              this.form.datasets,
              this.formUsageTab.datasets,
            );

            // Récupération des datasets depuis le RD
            const datasets: Datasets = {};
            Promise.all(
              Object.keys(datasetsConfiguration).map(async (alias) => {
                const value = datasetsConfiguration[alias];
                if (value && typeof value === 'string') {
                  const datasetName = value;
                  // Il s'agit d'un alias, donc d'un dataset à requêter
                  // Si le cache existe, le dataset n'est pas requêté à nouveau
                  if (!Object.keys(datasets).includes(datasetName)) {
                    datasets[alias] = await this.http.loadDataset(datasetName);
                    datasets[alias].$datasetName = datasetName;
                  }
                } else {
                  // Il s'agit de valeurs brutes (non d'un alias)
                  datasets[alias] = value as Dataset;
                }
              }),
            )
              .then(() => {
                // Tous les datasets ont été chargés
                // On ajoute à la config celle du formUsage
                if (this.formUsage?.config) {
                  datasets.config = _.merge(
                    {},
                    datasets.config,
                    this.formUsage.config,
                    this.formUsageTab?.datasets?.config,
                  );
                }

                // On fusionne tout dans le tab
                // On fusionne tout dans le formulaire
                if (this.form) this.form.datasets = datasets;
                // if (this.formUsageTab) this.formUsageTab.datasets = datasets;
              })
              .then(() => {
                // Fin du chargement
                this.$nextTick().then(() => {
                  setTimeout(() => {
                    this.loading = false;
                  }, 500);
                });
              });
          } else this.loading = false;
        });
    }
  }

  // Peut être appelée depuis ce composant et ses fils
  getDatasetValue(form: Form, datasetName: string, key: string): string | boolean {
    if (this.form && Object.keys(this.form.datasets).includes(datasetName)) {
      return (this.form.datasets[datasetName] as Dataset)[key] || '';
    }
    return '';
  }

  computeEffects(form: Form, effects: FormEffect[]) {
    if (!effects) return [];
    const r: string[] = [];
    effects.forEach((effect) => {
      if (effect.type && effect.on.length === 3) {
        const operator = effect.on[1];
        const compareTo = effect.on[2];
        const parts = effect.on[0].split('.');
        if (parts.length === 2) {
          const value = this.getDatasetValue(form, parts[0], parts[1]);
          let add = false;
          switch (operator) {
            case '===':
              add = value === compareTo;
              break;
            case '!==':
              add = value !== compareTo;
              break;
            case '>':
              add = value > compareTo;
              break;
            case '<':
              add = value < compareTo;
              break;
            case '<=':
              add = value <= compareTo;
              break;
            case '>=':
              add = value >= compareTo;
              break;
            default:
              add = false;
          }
          if (add && r.indexOf(effect.type) === -1) r.push(effect.type);
        }
      }
    });
    return r;
  }

  computeString(str: string) {
    const rx = new RegExp(/\[\[(\S+)\.(\S+)\]\]/g);
    return _.replace(str, rx, (match, dataset, key) => {
      if (this.form) {
        const v = this.getDatasetValue(this.form, dataset, key);
        if (v !== undefined) return String(v);
      }
      return '!#VAL';
    });
  }

  fieldGroupHasEffect(form: Form, fieldGroup: FormFieldsGroup, effect: string) {
    return this.computeEffects(form, fieldGroup.effects).indexOf(effect) !== -1;
  }

  updateDocumentTitle() {
    if (this.site && this.formUsage) {
      document.title = `${this.formUsage.name} - ${this.site.name} - MyPlay`;
    }
  }

  /**
   * 1 = côté
   * 0 = haut (horizontal)
   */
  get mode() {
    if (this.previewZoom) return 0;
    if (this.formUsage?.preview) {
      if (this.formUsage.preview.orientation !== 'vertical') return 0;
      if (this.$vuetify.breakpoint.lgAndUp) return 1;
    }
    return 0;
  }

  get prevFormUsage(): FormUsage | null {
    if (this.site) {
      const { slug } = this.$router.currentRoute.params;
      const index = this.site.formUsages.findIndex((formUsage) => formUsage.slug === slug);
      if (index > 0) return this.site.formUsages[index - 1];
    }
    return null;
  }

  get nextFormUsage(): FormUsage | null {
    if (this.site) {
      const { slug } = this.$router.currentRoute.params;
      const index = this.site.formUsages.findIndex((formUsage) => formUsage.slug === slug);
      if (index > -1 && index < this.site.formUsages.length - 1) {
        return this.site.formUsages[index + 1];
      }
    }
    return null;
  }

  @Watch('site')
  wSite() {
    this.updateDocumentTitle();
  }

  @Watch('formUsage')
  wFormUsage() {
    this.updateDocumentTitle();
  }
}


import Collection from '@shared/store/models/Collection';
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

const props = Vue.extend({
  props: [
    'label',
    'collection',
    'multiple',
    'params',
    'hint',
    'rules',
    'value',
    'effects',
    'disabled',
  ],
});

@Component
export default class MpTextarea extends mixins(Vue, props) {
  get arrayValue() {
    if (!this.value) return [];
    if (this.multiple) return this.value.split(',');
    return this.value;
  }

  get _collection(): Collection | null {
    if (!this.collection) return null;
    return this.$store.getters.getCollectionById(this.collection);
  }

  get rows() {
    return Math.min(Number(this.params.maxRows) || 4, 8);
  }

  blur(event: any) {
    if ((this.$refs.input as any).hasError) return;
    this.$emit('input', event.target.value);
  }
}


import { Component, Vue } from 'vue-property-decorator';
import User from '@shared/store/models/User';
import SiteSearch from '@shared/components/SiteSearch.vue';
import Site from '@shared/store/models/Site';

@Component({
  components: { SiteSearch },
})
export default class Home extends Vue {
  get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  get isMarketing() {
    return this.$store.getters.isMarketing;
  }

  get user(): User | null {
    return this.$store.state.user;
  }

  siteSelect(selection: Site | null) {
    if (selection) this.goToSite(selection._id);
  }

  goToSite(id: string) {
    this.$router.push({ name: 'site_home', params: { id } });
  }
}

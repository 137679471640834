
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import LoginCredentials from '@shared/store/models/LoginCredentials';
import HttpMixin from '@shared/http/http.mixin';
import SnackMixin from '../app/mixins/snack.mixin';
import { SnackType } from '../app/app.constants';

@Component
export default class Login extends mixins(Vue, HttpMixin, SnackMixin) {
  credentials: LoginCredentials = {};

  loginLoading = false;

  loginOk = false;

  mounted() {
    if (this.$store.state.authenticated) {
      this.$router.push({ name: 'home' });
    }
  }

  submit(e: Event) {
    this.loginLoading = true;
    this.http.login(this.credentials).then(
      () => {
        // Actualiser permet de re-lancer le bootstrap de l'app
        // Voir composant App.
        this.loginOk = true;
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (reason) => {
        this.loginLoading = false;
        if (reason.response.status === 400) {
          this.snack({
            type: SnackType.ERROR,
            text: "Les identifiants saisis sont incorrects ou le compte utilisateur n'existe pas.",
            timeout: 10000,
          });
        } else {
          this.snack({
            type: SnackType.ERROR,
            text: `Une erreur inconnue est survenue. (code ${reason.response.status})`,
          });
        }
      },
    );

    e.preventDefault();
  }

  get loading() {
    return this.$store.state.loading;
  }
}


import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@shared/http/http.mixin';
import FormField from '@shared/store/models/FormField';
import Infos from './Infos.vue';
import Toggle from './Toggle.vue';
import MpSelect from './Select.vue';
import MpTextarea from './MpTextarea.vue';

const props = Vue.extend({
  props: ['field', 'form', 'value', 'effects', 'computeString'],
});

const FIELD_STATES = {
  INITIALIZING: 'initializing',
  OK: 'ok',
  SAVING: 'saving',
  ERROR: 'error',
};

@Component({
  components: {
    Infos,
    Toggle,
    MpSelect,
    MpTextarea,
  },
})
export default class Field extends mixins(Vue, HttpMixin, props) {
  dialog = false;

  state = FIELD_STATES.OK;

  FIELD_STATES = FIELD_STATES;

  field!: FormField;

  async updateValue(value: string) {
    if (this.key && this.form.datasets[this.field.alias]?.$datasetName) {
      const realDatasetName = this.form.datasets[this.field.alias].$datasetName;
      this.state = FIELD_STATES.SAVING;
      await this.http.setDatasetValue(realDatasetName, this.key, value).then(
        () => {
          // this.form.datasets[this.field.alias][this.key] = value;
          Vue.set(this.form.datasets[this.field.alias], this.key, value);
          this.state = FIELD_STATES.OK;
        },
        () => {
          this.state = FIELD_STATES.ERROR;
        },
      );
    }
  }

  hasEffect(effect: string) {
    if (!this.effects) return false;
    return this.effects.indexOf(effect) !== -1;
  }

  get _collection() {
    return this.computeString(this.field.params.collection);
  }

  get rules() {
    console.log('rules', this.field);

    const r = [];
    if (this.field.params.pattern) {
      r.push(
        (value: string) =>
          (value && value.match(this.field.params.pattern) !== null) ||
          'La valeur ne correspond pas au format attendu.',
      );
    }
    if (this.field.params.maxLength) {
      const computedMaxLength = Number(this.computeString(this.field.params.maxLength));
      if (computedMaxLength) {
        r.push(
          (value: string) =>
            !value ||
            (value && value.length <= computedMaxLength) ||
            `La valeur ne doit pas dépasser ${computedMaxLength} caractères`,
        );
      }
    }

    if (this.field.params.maxRows) {
      const computedMaxRows = Number(this.computeString(this.field.params.maxRows));
      if (computedMaxRows) {
        r.push(
          (value: string) =>
            !value ||
            (value && (value.match(/\n/g) || []).length < computedMaxRows) ||
            `${computedMaxRows} lignes maximum`,
        );
      }
    }
    return r;
  }

  get name() {
    return this.computeString(this.field.name);
  }

  get key() {
    return this.computeString(this.field.key);
  }

  get hint() {
    if (!this.field.params.hint) return '';
    if (!this.computeString) return this.field.params.hint;
    return this.computeString(this.field.params.hint);
  }
}

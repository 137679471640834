var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.formUsage && _vm.site)?_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"headline d-flex",attrs:{"translate":"no"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"primary","icon":"","exact":"","to":{ name: 'site_home', params: { id: _vm.site._id } }}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_vm._v(" "+_vm._s(_vm.site.customerId)+" "+_vm._s(_vm.site.name)+" "),_c('span',{staticClass:"title pl-2"},[_vm._v("> "+_vm._s(_vm.formUsage.name))]),_c('v-spacer'),_c('div',{staticClass:"actions"},[(_vm.prevFormUsage)?_c('v-btn',{attrs:{"text":"","to":{
                name: 'form',
                params: { siteId: _vm.site._id, slug: _vm.prevFormUsage.slug },
                query: { auto: null },
              },"exact":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" "+_vm._s(_vm.prevFormUsage.name)+" ")],1):_vm._e(),(_vm.nextFormUsage)?_c('v-btn',{attrs:{"text":"","to":{
                name: 'form',
                params: { siteId: _vm.site._id, slug: _vm.nextFormUsage.slug },
                query: { auto: null },
              },"exact":""}},[_vm._v(" "+_vm._s(_vm.nextFormUsage.name)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1):_vm._e()],1)],1)],1)],1)],1),_c('v-row',{attrs:{"align":"stretch"}},[_c('v-col',{attrs:{"cols":_vm.mode ? 9 : 12,"order":_vm.mode ? 1 : 2}},[(_vm.form)?_c('v-card',{staticClass:"fill-height",attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[_c('v-toolbar',{attrs:{"tile":"","flat":"","elevation":"0"}},[_c('v-spacer'),_vm._l((_vm.formUsage.tabs),function(tab,index){return _c('v-btn',{key:index,attrs:{"text":"","tile":"","to":{
              name: 'form_tab',
              params: {
                siteId: _vm.site && _vm.site._id,
                slug: _vm.formUsage && _vm.formUsage.slug,
                tabNo: index + 1,
              },
            }}},[_vm._v(" "+_vm._s(tab.name)+" ")])}),_c('v-spacer')],2),_c('v-divider'),(_vm.formUsageTab)?_c('v-card-text',[_c('v-row',[_vm._l((_vm.form.groups),function(fGroup,j){return [(_vm.form && !_vm.fieldGroupHasEffect(_vm.form, fGroup, 'hide'))?_c('v-col',{key:j,attrs:{"sm":"12","lg":"6","xl":"4"}},[_c('v-card',{staticClass:"elevation-3 fill-height"},[(_vm.form && _vm.form.groups.length > 1)?_c('v-card-title',{staticClass:"title pa-4"},[_vm._v(_vm._s(fGroup.name))]):_vm._e(),_c('v-card-text',{staticClass:"pa-4"},[_vm._l((fGroup.fields),function(field,k){return [(_vm.form)?_c('field',{key:k,attrs:{"field":field,"form":_vm.form,"computeString":_vm.computeString,"value":_vm.getDatasetValue(_vm.form, field.alias, _vm.computeString(field.key)),"effects":_vm.computeEffects(_vm.form, field.effects)}}):_vm._e()]})],2)],1)],1):_vm._e()]})],2)],1):_vm._e()],1):_vm._e()],1),(_vm.formUsage.preview)?_c('v-col',{attrs:{"cols":_vm.mode ? 3 : 12,"order":_vm.mode ? 2 : 1}},[_c('preview',{attrs:{"limitHeight":_vm.mode === 0 && !_vm.previewZoom,"zoom":_vm.previewZoom,"orientation":_vm.formUsage.preview.orientation,"url":_vm.formUsage.preview.url}},[(!_vm.previewZoom)?_c('v-btn',{attrs:{"icon":"","text":"","color":"secondary","title":"Agrandir l'aperçu"},on:{"click":function($event){_vm.previewZoom = !_vm.previewZoom}}},[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")])],1):_vm._e(),(_vm.previewZoom)?_c('v-btn',{attrs:{"icon":"","text":"","color":"red darken-2","title":"Taille normal"},on:{"click":function($event){_vm.previewZoom = !_vm.previewZoom}}},[_c('v-icon',[_vm._v("mdi-magnify-minus-outline")])],1):_vm._e()],1)],1):_vm._e()],1),_c('v-snackbar',{attrs:{"bottom":"","right":"","timeout":5000},model:{value:(_vm.snackbarDayOfWeekTab),callback:function ($$v) {_vm.snackbarDayOfWeekTab=$$v},expression:"snackbarDayOfWeekTab"}},[_vm._v("Vous avez été placé automatiquement dans l'onglet du jour.")])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }
import axios from '@shared/http/axios';
import Http from '@shared/http/http';
import Communication from '@shared/store/models/Communication';
import List from '@shared/store/models/List';
import LoginCredentials from '@shared/store/models/LoginCredentials';
import User from '@shared/store/models/User';
import mutations from '@shared/store/mutations';
import Vue from 'vue';
import Component from 'vue-class-component';
import axiosRepository from './axios.repository';

@Component
export default class HttpMixin extends Vue {
  protected http: Http;

  constructor() {
    super();
    const store = this.$store;
    this.http = {
      async getMyUser() {
        return axios.get('/users/me').then((response) => {
          //
          const user: User = response.data;
          // store.commit(mutations.USER_SET, user);
          return user;
        });
      },

      async loadCollections() {
        return axios.get('/collections').then((response) => response.data);
      },

      async loadSiteById(id: string) {
        return axios.get(`/sites/${id}`).then((response) => response.data);
      },

      async loadForms() {
        return axios.get('/forms').then((response) => response.data);
      },

      async loadCommunications() {
        return axios.get('/communications').then((response) => response.data);
      },

      async loadCommunicationById(id: string) {
        return axios
          .get(`/communications/${id}`)
          .then((response) => response.data);
      },

      async loadCommunicationsForSite(siteId: string) {
        return axios
          .get(`/communications/site/${siteId}`)
          .then((response) => response.data);
      },

      async loadCommunicationsForList(listId: string) {
        return axios
          .get(`/communications/list/${listId}`)
          .then((response) => response.data);
      },

      async saveCommunication(communication: Communication) {
        return axios
          .post('/communications', communication)
          .then((response) => response.data);
      },

      async updateCommunication(communication: Communication) {
        return axios
          .put(`/communications/${communication._id}`, communication)
          .then((response) => response.data);
      },

      async loadLists() {
        return axios.get('/lists').then((response) => response.data);
      },

      async saveList(list: List) {
        return axios.post('/lists', list).then((response) => response.data);
      },

      async updateList(list: List) {
        return axios
          .put(`/lists/${list._id}`, list)
          .then((response) => response.data);
      },

      async login(credentials: LoginCredentials) {
        return axios
          .post('users/auth/login', {
            email: credentials.email,
            password: credentials.password,
          })
          .then(() => this.getMyUser());
      },

      async logout() {
        return axios.get('/users/auth/logout').then(() => {
          store.commit(mutations.USER_SET, null);
        });
      },

      async uploadFileRD(formData: FormData, onUploadProgress: Function) {
        return axiosRepository.post('/upload', formData, {
          onUploadProgress: (e) => {
            const percentCompleted = Math.round((e.loaded * 100) / e.total);
            if (typeof onUploadProgress === 'function') {
              onUploadProgress(percentCompleted);
            }
          },
        });
      },

      async loadDataset(name: string) {
        return axiosRepository
          .get(
            `/${
              // L'URL est légèrement différente entre la v1 et la v2
              process.env.VUE_APP_REPOSITORY_VERSION === 'v1'
                ? 'all'
                : 'datasets'
            }/${name}`,
          )
          .then((response) => response.data);
      },

      async setDatasetValue(name: string, key: string, value: string | any) {
        // Repository v1
        if (process.env.VUE_APP_REPOSITORY_VERSION === 'v1') {
          let stringValue: string;
          if (typeof value === 'string') stringValue = value;
          else stringValue = JSON.stringify(value);

          return axiosRepository.post(`/set/${name}/${key}`, stringValue, {
            headers: {
              'Content-Type': 'text/plain',
            },
          });
        }

        // Repository v2+
        return axiosRepository.post(`/datasets/${name}`, {
          [key]: value,
        });
      },
    };
  }
}

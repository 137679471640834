
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import HttpMixin from '@shared/http/http.mixin';
import { Watch } from 'vue-property-decorator';
import Site from '@shared/store/models/Site';
import mutations from '@shared/store/mutations';

@Component
export default class SiteHome extends mixins(Vue, HttpMixin) {
  async beforeMount() {
    this.$store.commit(mutations.LOADING, true);
    const route = this.$router.currentRoute;
    if (route.name === 'site_home' && route.params.id) {
      const site = await this.http.loadSiteById(route.params.id);
      this.$store.commit(mutations.SITE_SELECT, site);
    }
    this.$store.commit(mutations.LOADING, false);
  }

  get site(): Site | undefined {
    return this.$store.state.selectedSite;
  }

  @Watch('site')
  wSite(site: Site) {
    if (site) document.title = `${site.customerId} ${site.name} - MyPlay`;
  }

  get showLocationSyncExtension() {
    return typeof this.$options.components?.LocationSyncComponent !== 'undefined';
  }
}


import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';

const props = Vue.extend({
  props: ['label', 'hint', 'value', 'disabled'],
});

@Component
export default class Toggle extends mixins(Vue, props) {
  get trueValue() {
    if (process.env.VUE_APP_REPOSITORY_VERSION === 'v1') {
      return 'b:true';
    }
    return true;
  }

  get falseValue() {
    if (process.env.VUE_APP_REPOSITORY_VERSION === 'v1') {
      return 'b:false';
    }
    return false;
  }
}
